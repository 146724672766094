<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="loginForm"
      #default="{ invalid }"
    >
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Senha Atual"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Senha Atual"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null || showError === true ? 'is-invalid':null"
                >
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    :state="errors.length > 0 ? false:null || showError === true ? false:null"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Senha Atual"
                    @focus="showError=false"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <small
                v-if="showError"
                class="text-danger"
              >{{ textError }}</small>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="Nova Senha"
            >
              <validation-provider
                #default="{ errors }"
                name="Nova Senha"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :state="errors.length > 0 ? false:null"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Nova Senha"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Confirmar Senha"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirmar Senha"
                rules="required|confirmed:Nova Senha"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :state="errors.length > 0 ? false:null"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Confirmar Senha"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="invalid"
              variant="primary"
              class="mt-1 mr-1"
              @click="request()"
            >
              Salvar Mudanças
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              @click="redirectHome()"
            >
              Cancelar
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';

import { required, email } from '@validations';
import AuthService from '@/services/osiris/AuthService';

export default {
    components: {
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BInputGroup,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            passwordValueOld: '',
            newPasswordValue: '',
            RetypePassword: '',
            passwordFieldTypeOld: 'password',
            passwordFieldTypeNew: 'password',
            passwordFieldTypeRetype: 'password',
            checker: false,
            showError: false,
            textError: null,
            required,
            email,
        };
    },
    computed: {
        passwordToggleIconOld() {
            return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        },
        passwordToggleIconNew() {
            return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        },
        passwordToggleIconRetype() {
            return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        },
    },
    methods: {
        togglePasswordOld() {
            this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password';
        },
        togglePasswordNew() {
            this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password';
        },
        togglePasswordRetype() {
            this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password';
        },
        async request() {
            await this.callModal();
            if (!this.checker) return;
            this.isLoading(true);
            const request = {
                old_password: this.passwordValueOld,
                new_password: this.newPasswordValue,
                confirm_password: this.RetypePassword,
            };
            try {
                const response = await AuthService.resetPassword(request);
                if (response.status === 200) {
                    this.$swal({
                        icon: 'success',
                        html: '<span>Senha Alterada com Sucesso!</span> ',
                        customClass: {
                            confirmButton: 'btn btn-primary btn-modal',
                        },
                        buttonsStyling: false,
                    });
                    this.$router.push('/');
                } else {
                    this.showError = true;
                    this.textError = 'Digite corretamente sua senha atual';
                    this.isLoading(false);
                }
            } catch (err) {
                this.showError = true;
                this.textError = 'Digite corretamente sua senha atual';
                this.isLoading(false);
            } finally {
                this.checker = false;
            }
        },
        async callModal() {
            await this.$bvModal
                .msgBoxConfirm('Deseja Alterar a Senha?', {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    if (value) {
                        this.checker = value;
                    }
                });
        },
        redirectHome() {
            this.$router.push('/');
        },
    },
};
</script>
